import React from "react"
import Layout from "../components/Layout" 
import { graphql, Link } from "gatsby"

import img from "../images/contact/contact_main.jpg"

const WorkPage = ({ data }) => {

  return (
    <>
      <Layout>
        <div className="page-wrapper agenda">
          <div className="main-image">
            <img src={img}/>
          </div>
          <h1>CONTACT</h1>
          <p>Une demande de documentation ? une réservation ? tout autre renseignement ou prise de contact ?</p>
          <p>La compagnie Bêta reste à votre disposition.</p>
          <a href="mailto:contact@aloisleclet.fr">contact@aloisleclet.fr</a>

          <h1>NEWSLETTER</h1>
          <p>Recevez les actualitées importantes de la compagnie (1 par mois maximum)</p>
          <a href="https://framaforms.org/compagnie-beta-inscription-a-la-newsletter-1694013107">S'inscrire</a>
          
          <h1>RÉSEAUX SOCIAUX</h1>

          <p><b>Instagram: </b><a href="https://instagram.com/cie_beta" target="_blank" rel="noopener">@cie_beta</a></p>

          <div>
              <Link className="button center" to="/">Accueil</Link>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default WorkPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
